import { NavLink} from "@remix-run/react";
function Header(){
    return(
        <header className="page-header page-header-transparent">
      <div className="page-header__inner">
        <div className="page-header__left">
          <div className="logo"><NavLink className="logo__link" to="/">
              <div className="logo__icon"><img src="/metam-logo.png" alt="Alanya Yetkili Honda Bayi Metam Alanya Motor"/></div>              
            </NavLink></div>
        </div>
        <div className="page-header__center">
          <nav className="page-nav" data-uk-navbar>
            <ul className="uk-navbar-nav">             
              <li><NavLink to="/">Ana Sayfa</NavLink></li>
              <li><NavLink to="/HAKKIMIZDA">Hakkımızda</NavLink></li>
              <li><NavLink to="/HONDA-MODEL">Modeller</NavLink></li>
              <li><NavLink to="/Haberler">Haberler</NavLink></li>
              <li><NavLink to="/ILETISIM">İletişim</NavLink></li>
            </ul>
          </nav>
        </div>
        <div className="page-header__right">
        <div className="page-header__support">
            <div className="support semih"><a className="support__link" href="tel:00902425254050">
                <div className="support__icon"><img src="/assets/img/icons/phone.svg" alt="Honda Alanya İletişim"/></div>
                <div className="support__desc">
                  <div className="support__label">İletişim</div>
                  <div className="support__phone">0 242 525 40 50</div>
                </div>
              </a>
            </div>
          </div>
          <a className="uk-navbar-toggle menu-btn" uk-toggle="target: #offcanvas" href="#" data-uk-toggle><img src="/assets/img/icons/menu.svg" alt="menu"/></a>

        </div>
          
      </div>
      
    </header>
    )
}
export default Header;