import { NavLink, useLoaderData } from "@remix-run/react";
import moment from "moment";
import { IMG_URL } from "../helpers/def";

export function Footer({news}){
    return(
        <footer className="page-footer">
      <div className="page-footer__top">
        <div className="uk-container">
          <div className="uk-grid uk-grid-small uk-child-width-1-4@l uk-child-width-1-2@s" data-uk-grid>
            <div>
              <div className="uk-margin-medium">
                <div className="logo"><NavLink className="logo__link" to="/">
                    <div className="logo__icon"><img src="/metam-logo.png" alt="Honda Alanya"/></div>
                    <div className="logo__text">Honda Alanya</div>
                  </NavLink></div>
              </div>
              <div className="uk-margin-medium">
                <p>"Honda Motosiklet Alanya yetkili satış ve servis bayisi olarak , hizmet vermeye başladığı günden itibaren, Honda Motosiklet müşterilerini ve Honda Motosiklet sahibi olmak isteyenleri “Mükemmel Müşteri Deneyimi” sloganıyla karşılamayı hedefliyoruz."</p>
              </div>
              <div className="uk-margin-medium">
                <div className="support"><a className="support__link" href="tel:00902425254050">
                    <div className="support__icon"><i className="fas fa-headset"></i></div>
                    <div className="support__desc">
                      <div className="support__label">Çağrı Merkezi</div>
                      <div className="support__phone">0 242 525 40 50</div>
                    </div>
                  </a></div>
              </div>
              <div className="uk-margin-medium">
                <div className="social">
                  <ul className="social-list">
                    <li className="social-list__item"><a className="social-list__link" href="#!"><i className="fab fa-twitter"></i></a></li>
                    <li className="social-list__item"><a className="social-list__link" href="#!"><i className="fab fa-facebook-f"></i></a></li>
                    <li className="social-list__item"><a className="social-list__link" href="#!"><i className="fab fa-google-plus-g"></i></a></li>
                    <li className="social-list__item"><a className="social-list__link" href="#!"><i className="fab fa-youtube"></i></a></li>
                    <li className="social-list__item"><a className="social-list__link" href="#!"><i className="fab fa-pinterest-p"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
            <div>
              <div className="page-footer__column page-footer__menu">
                <div className="uk-h4">Metam Alanya Motor</div>
                <ul className="list-decor">
              <li><NavLink to="/HAKKIMIZDA">Hakkımızda</NavLink></li>
              <li><NavLink to="/HONDA-MODEL">Modeller</NavLink></li>
              <li><NavLink to="/Haberler">Haberler</NavLink></li>
              <li><NavLink to="/ILETISIM">İletişim</NavLink></li>
                </ul>
              </div>
            </div>
            <div>
              <div className="page-footer__column page-footer__news">
                <div className="uk-h4">En Son Haberler</div>
                <ul className="latest-news-list">
                {news?.map((news, index) => (
                  <li key={index}>
                    <div className="latest-news-item">
                      <div className="latest-news-item__thumb"><img src={IMG_URL+'mc/70/70/6/news/'+news.cover} alt={news.title}/></div>
                      <div className="latest-news-item__info"><NavLink className="latest-news-item__title" to={'/haberler/'+news.href}>{news.title}</NavLink>
                        <div className="latest-news-item__date">{moment(news.updatedAt).format('d.MM.yyyy')}</div>
                      </div>
                    </div>
                  </li>
                ))}
                </ul>
              </div>
            </div>
            <div>
              <div className="page-footer__column page-footer__info">
                <div className="uk-h4">Çalışma Saatleri</div>
                <ul className="info-list">
                  <li>
                    <div className="info-list-item">
                      <div className="info-list-item__title">Satış</div>
                      <div className="info-list-item__value">Pazartesi - Cumartesi: <br/> 08:30 - 17:00</div>
                    </div>
                  </li>
                  <li>
                    <div className="info-list-item">
                      <div className="info-list-item__title">Servis</div>
                      <div className="info-list-item__value">Pazartesi - Cumartesi: <br/> 08:30 - 17:00</div>
                    </div>
                  </li>
                  <li>
                    <div className="info-list-item">
                      <div className="info-list-item__title">Yedek Parça</div>
                      <div className="info-list-item__value">Pazartesi - Cumartesi: <br/> 08:30 - 17:00</div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="page-footer__bottom">
        <div className="uk-container">
          <div className="uk-text-center">{'(c) '+new Date().getFullYear()+' METAM ALANYA MOTOR | Yetkili Honda Satış ve Servis Bayi'}</div>
        </div>
      </div>
      <div id="offcanvas" data-uk-offcanvas="flip: true; overlay: true; mode: push">
        <div className="uk-offcanvas-bar"><button className="uk-offcanvas-close" type="button" data-uk-close=""></button>
          <div className="uk-margin">
            <div className="logo"><NavLink className="logo__link" to='/'>
                <div className="logo__icon"><img src="/metam-logo.png" alt="Honda Alanya"/></div>
                <div className="logo__text">HONDA ALANYA</div>
              </NavLink></div>
          </div>
          <div className="uk-margin">
              
              
            <ul className="uk-nav uk-nav-default uk-nav-parent-icon" data-uk-nav>
            <li><a href="/">Ana Sayfa</a></li>
              <li><a href="/HAKKIMIZDA">Hakkımızda</a></li>
              <li><a href="/HONDA-MODEL">Modeller</a></li>
              <li><a href="/Haberler">Haberler</a></li>
              <li><a href="/ILETISIM">İletişim</a></li>
            </ul>
          </div>
          <div className="uk-margin-auto-top">
          <div className="support"><a className="support__link" href="tel:00902425254050">
                    <div className="support__icon"><i className="fas fa-headset"></i></div>
                    <div className="support__desc">
                      <div className="support__label">Çağrı Merkezi</div>
                      <div className="support__phone">0 242 525 40 50</div>
                    </div>
                  </a></div>
          </div>
        </div>
      </div>
      <div className="uk-modal-full uk-modal" id="modal-search" data-uk-modal>
        <div className="uk-modal-dialog uk-flex uk-flex-center uk-flex-middle" data-uk-height-viewport><button className="uk-modal-close-full" type="button" data-uk-close></button>
          <form className="uk-search uk-search-large"><input className="uk-search-input uk-text-center" type="search" placeholder="Search..." autoFocus /></form>
        </div>
      </div>
    </footer>
    )
}